import Cookies from "universal-cookie";

import {
    StoredActionTypes,
    SetLoadingType,
    SetStoredProductAllType,
    SetStoredProductType,
    SetStoredTotalsType,
    StoredDict,
    StoredProductListItem,
    StoredThunkType,
} from "./types/StoredTypes/stored.types";
import { storedProduct } from "../api/storedProductApi";

export const SET_STORED_PRODUCT = "SET_STORED_PRODUCT";
export const SET_STORED_TOTAL = "SET_STORED_TOTAL";
export const SET_LOADING = "SET_LOADING";
export const SET_STORED_PRODUCT_ALL = "SET_STORED_PRODUCT_ALL";

type InitialStateType = {
    storedProduct: null | StoredProductListItem[];
    storedProductAll: null | StoredProductListItem[];
    storedTotals: null | StoredDict;
    isLoading: boolean;
};

let initialState: InitialStateType = {
    storedProduct: null,
    storedProductAll: null,
    storedTotals: null,
    isLoading: false,
};

const storedProductReducer = (state = initialState, action: StoredActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_STORED_PRODUCT: {
            return {
                ...state,
                storedProduct: action.data,
            };
        }
        case SET_STORED_PRODUCT_ALL: {
            return {
                ...state,
                storedProductAll: action.data,
            };
        }
        case SET_STORED_TOTAL: {
            return {
                ...state,
                storedTotals: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetStoredProduct = (data: Array<StoredProductListItem>): SetStoredProductType => ({
    type: SET_STORED_PRODUCT,
    data,
});
export const SetStoredProductAll = (data: Array<StoredProductListItem>): SetStoredProductAllType => ({
    type: SET_STORED_PRODUCT_ALL,
    data,
});
export const SetStoredTotals = (data: StoredDict): SetStoredTotalsType => ({
    type: SET_STORED_TOTAL,
    data,
});

export const SetLoading = (loading: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: loading,
});

export const getStoredProduct = (cookies: Cookies, country_code?: string): StoredThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await storedProduct.getStoredProduct(cookies, country_code);
            dispatch(SetStoredProduct(response.data.results));
            dispatch(SetStoredTotals(response.data.stored_dict));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};
export const getStoredProductAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    search: string,
    setStoredProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): StoredThunkType => {
    return async (dispatch) => {
        try {
            const response = await storedProduct.getStoredProductAll(cookies, items, offset, search, country_code, brand);
            setStoredProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetStoredProductAll(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export default storedProductReducer;
